<template>
  <div class="page-header" v-if="multipassConfig && multipassConfig.id">
    <div class="multipass-detail">
      <router-link
        class="multipass-name"
        :to="{
          name: 'multipass-detail',
          params: {
            lookup_ref: multipassConfig.lookup_ref,
          },
          query: $defaultQuery(),
        }"
      >
        {{ multipassConfig.name }}
      </router-link>

      <div class="price-wrapper mt-2">
        <div class="price">
          <b>{{ multipassConfig.min_total_price | currency }}</b>
        </div>
        <sup v-if="multipassConfig.sales_channel.fee_price">
          *
          <translate>precio sin gastos de gestión</translate>
        </sup>
      </div>
    </div>
  </div>
</template>

<script>
import filters from "@/filters"

export default {
  name: "MultipassConfigHeader",
  filters: filters,
  props: ["multipassConfig"],
}
</script>

<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.multipass-name
  font-size: 1.8rem
  line-height: 2rem
    margin-bottom: .5rem

.multipass-detail
    flex-grow: 1
    @include until($mobile)
      .multipass-name
        font-size: 1.6em
        margin: 0
</style>
