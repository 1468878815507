







import Vue from "vue"
import { Component } from "vue-property-decorator"
import { mapState } from "vuex"

@Component({
  computed: {
    ...mapState({
      cart: (state: any) => state.multipassCart.cart,
      numTickets: (state: any) => state.multipassCart.numTickets,
    }),
  },
})
export default class MultipassCartIcon extends Vue {
  toggleCartVisibility() {
    this.$store.dispatch("multipassCart/update", ["toggle"])
  }
}
