

























import "reflect-metadata"
import { Component, Watch } from "vue-property-decorator"
import MultipassConfigHeader from "@/components/multipass/MultipassConfigHeader.vue"
import MultipassCartIcon from "@/components/multipass/MultipassCartIcon.vue"
import TokenTimer from "@/components/TokenTimer.vue"
import Vue from "vue"
import TokenExpiredDialog from "@/components/TokenExpiredDialog.vue"

@Component({
  components: {
    MultipassConfigHeader,
    MultipassCartIcon,
    TokenTimer,
    TokenExpiredDialog,
  },
})
export default class Multipass extends Vue {
  multipassConfig: any = null
  tokenExpired = false

  mounted() {
    this.getMultipassConfig()
  }

  getMultipassConfig() {
    this.$api.getMultipassConfig(this.$route.params?.lookupRef).then(
      (response: any) => {
        this.multipassConfig = response
      },
      () => {}
    )
  }

  get isLoading() {
    return !this.multipassConfig?.id
  }

  get ffTokenTimerEnabled() {
    return this.$env && this.$env.ff_token_timer === true
  }

  @Watch("$route.params.lookupRef")
  onRouterParamlookupRefChange() {
    this.getMultipassConfig()
  }
}
